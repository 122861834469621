<template>
  <div>
    <Navbar/>
    <div class="topo goop-topo">
      <div class="container">
        <h1>A Goop</h1>
      </div>
    </div>
    <div class="conteudo">
      <div class="container">
        <p>Nossa origem, se deu da vontade de dar certo e da persistência em desbravar o mercado de autopeças em busca de conhecimento e novas possibilidades. </p>
        <p>Até chegarmos aqui, nos esforçamos ao máximo: demos nosso melhor, aprendemos, cultivamos relacionamentos e nos empenhamos não apenas para estarmos inserido neste mercado, mas para sermos uma referência nele. </p>
        <p>Anos depois, aqui estamos, com nosso motor funcionando a todo vapor e com uma empresa que tem em sua essência a vontade de servir, dedicação na entrega de ferramentas certas para turbinar seu negócio automotivo e ajudar a formar uma comunidade cada vez mais potente - que troca e cresce em rede, num círculo veloz e virtuoso.</p>
      </div>
      <div class="container anos hide-sm">
        <div class="row">
          <div class="col-xs-12">
            <h1>No mercado desde os anos</h1>
            <h3>Nosso crescimento baseia-se em uma trajetória de coragem, vontade de estar sempre à frente e atenção as novas mudanças e tendências do cenário automotivo.</h3>
            <h5>Clique na bolinha para mudar o ano.</h5>
          </div>
        </div>
        <div class="row justify-content-center" style="margin-top: 40px">
          <div class="col-xs-12 col-md-9">
            <div class="row">
              <div class="col-md-7">
                <ul class="nav justify-content-center timeline timeline-horizontal" id="myTab" role="tablist">
                  <li class="nav-item timeline-item" role="presentation">
                    <p>2000</p>
                    <a class="nav-link timeline-badge active" id="2000-tab" data-toggle="tab" href="#aba1" role="tab" aria-controls="2000" aria-selected="true"></a>
                  </li>
                  <li class="nav-item timeline-item" role="presentation">
                    <p>2008</p>
                    <a class="nav-link timeline-badge" id="2008-tab" data-toggle="tab" href="#aba2" role="tab" aria-controls="2008" aria-selected="false"></a>
                  </li>
                  <li class="nav-item timeline-item" role="presentation">
                    <p>2011</p>
                    <a class="nav-link timeline-badge" id="2011-tab" data-toggle="tab" href="#aba3" role="tab" aria-controls="2011" aria-selected="false"></a>
                  </li>
                  <li class="nav-item timeline-item" role="presentation">
                    <p>2019</p>
                    <a class="nav-link timeline-badge" id="2019-tab" data-toggle="tab" href="#aba4" role="tab" aria-controls="2019" aria-selected="false"></a>
                  </li>
                </ul>
              </div>
              <div class="col-md-5">
                <div class="compl-timeline">
                  O que nos move vem do passado e direciona nosso futuro.
                </div>
              </div>
              <div class="col-md-12">
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="aba1" role="tabpanel" aria-labelledby="2000-tab">
                    <h2>2000 - Nasce a Goop</h2>
                    <p class="aba">Nasce a Goop, fundada pela Fátima Soares a empresa surge em uma pequena sala dentro da retífica MM no bairro da Vila Guilherme, Zona Norte de São Paulo, aos poucos a empresa cresce e no ano seguinte muda para o bairro da Vila Maria.</p>
                  </div>
                  <div class="tab-pane fade" id="aba2" role="tabpanel" aria-labelledby="2008-tab">
                    <h2>2008 - Entrada do André Simões</h2>
                    <p class="aba">Segundo diretor da companhia.</p>
                  </div>
                  <div class="tab-pane fade" id="aba3" role="tabpanel" aria-labelledby="2011-tab">
                    <h2>2011 - Marca takao</h2>
                    <p class="aba">Ano do nascimento da marca.</p>
                  </div>
                  <div class="tab-pane fade" id="aba4" role="tabpanel" aria-labelledby="2019-tab">
                    <h2>2019 - POSICIONAMENTO DA MARCA</h2>
                    <p class="aba">Trabalho no desenvolvimento da empresa e seu posicionamento no mercado, desenvolvimento de novos projetos e desafios.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row separador">
          <div class="col-md-5">
            <img src="../assets/imgs/a-goop/caminho.png" class="img-fluid">
          </div>
          <div class="col-md-2 text-center">
            <img src="../assets/imgs/a-goop/comp40-2.png" class="img-fluid center-block">
          </div>
          <div class="col-md-5 text-right">
            <img src="../assets/imgs/a-goop/caminho.png" class="img-fluid">
          </div>
        </div>
        <div class="row">
          <div class="col-md-4">
            <h1>Existimos para</h1>
            <p>turbinar o seu negócio automotivo.<br>Excelência e qualidade é o que nos move.</p>
          </div>
          <div class="offset-md-1 col-md-2 text-center" style="writing-mode: vertical-rl;">
            <img src="../assets/imgs/a-goop/goop.png" class="img-fluid">
          </div>
          <div class="offset-md-1 col-md-3">
            <h1>Somos</h1>
            <p>a solução completa para manutenção e reposição automotiva.</p>
          </div>
        </div>
        <div class="row separador">
          <div class="col-md-5">
            <img src="../assets/imgs/a-goop/caminho.png" class="img-fluid">
          </div>
          <div class="col-md-2 text-center">
            <h1>com</h1>
          </div>
          <div class="col-md-5 text-right">
            <img src="../assets/imgs/a-goop/caminho.png" class="img-fluid">
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="row">
              <div class="col-xs-12 col-md-3 cards">
                <img src="../assets/imgs/a-goop/gp45.png" class="img-fluid">
                <h2>Garra</h2>
                <p>A gente veste a camisa porque ama o que faz. Lutamos por um só time com esforço e empenho, mas confiamos em cada um para agir com autonomia e agilidade para resolver a vida do cliente.</p>
              </div>
              <div class="col-xs-12 col-md-3 cards">
                <img src="../assets/imgs/a-goop/gp1.png" class="img-fluid">
                <h2>OUSADIA</h2>
                <p>Ninguém se contenta com pouco por aqui. Sempre antenados, vemos o que todo mundo está fazendo para ir ainda mais além. Queremos antecipar o que o mercado precisa para sair na frente, aparando as arestas no caminho.</p>
              </div>
              <div class="col-xs-12 col-md-3 cards">
                <img src="../assets/imgs/a-goop/gp2.png" class="img-fluid">
                <h2>OLHO NO OLHO</h2>
                <p>Não gostamos de cerimônia, e sim de gente. Nossa equipe é próxima entre si e com cada cliente. E isso não significa informalidade. Mas sim respeito e empatia para fazer a diferença entendendo o que cada um precisa.</p>
              </div>
              <div class="col-xs-12 col-md-3 cards">
                <img src="../assets/imgs/a-goop/gp3.png" class="img-fluid">
                <h2>PERFORMANCE</h2>
                <p>Queremos desempenho máximo para resultados concretos. E para chegar até lá respeitamos e investimos em processos e pessoas para garantir que todo mundo esteja na mesma página, sempre com profissionalismo.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="row separador">
          <div class="col-md-5">
            <img src="../assets/imgs/a-goop/caminho.png" class="img-fluid">
          </div>
          <div class="col-md-2 text-center p-0">
            <h1>Para Quem</h1>
          </div>
          <div class="col-md-5 text-right">
            <img src="../assets/imgs/a-goop/caminho.png" class="img-fluid">
          </div>
          <div class="col-xs-12 col-md-12 text-center">
            <div class="span">pensa grande e busca um parceiro de confiança para evoluir e sair na frente.</div>
          </div>
        </div>
      </div>

      <div class="container anos hide-md">
        <div class="row">
          <div class="col">
            <h1>No mercado desde os anos 2000</h1>
            <p>Nosso crescimento baseia-se em uma trajetória de coragem, vontade de estar sempre à frente e atenção as novas mudanças e tendências do cenário automotivo.</p>
            <h1>O que nos move vem do passado e direciona nosso futuro.</h1>
            <h2>2000 - Nasce a Goop</h2>
            <p>Nasce a Goop, fundada pela Fátima Soares a empresa surge em uma pequena sala dentro da retífica MM no bairro da Vila Guilherme, Zona Norte de São Paulo, aos poucos a empresa cresce e no ano seguinte muda para o bairro da Vila Maria.</p>
            <h2>2008 - Entrada do André Simões</h2>
            <p>Segundo diretor da companhia.</p>
            <h2>2011 - Marca takao</h2>
            <p>Ano do nascimento da marca.</p>
            <h2>2019 - POSICIONAMENTO DA MARCA</h2>
            <p>Trabalho no desenvolvimento da empresa e seu posicionamento no mercado, desenvolvimento de novos projetos e desafios.</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h1>Existimos para</h1>
            <p>turbinar o seu negócio automotivo.<br>Excelência e qualidade é o que nos move.</p>
            <h1>Somos</h1>
            <p>a solução completa para manutenção e reposição automotiva.</p>
            <h1>Com</h1>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-sm-6 col-md-3 cards">
            <img src="../assets/imgs/a-goop/gp45.png" class="img-fluid">
            <h2>Garra</h2>
            <p>A gente veste a camisa porque ama o que faz. Lutamos por um só time com esforço e empenho, mas confiamos em cada um para agir com autonomia e agilidade para resolver a vida do cliente.</p>
          </div>
          <div class="col-12 col-sm-6 col-md-3 cards">
            <img src="../assets/imgs/a-goop/gp1.png" class="img-fluid">
            <h2>OUSADIA</h2>
            <p>Ninguém se contenta com pouco por aqui. Sempre antenados, vemos o que todo mundo está fazendo para ir ainda mais além. Queremos antecipar o que o mercado precisa para sair na frente, aparando as arestas no caminho.</p>
          </div>
          <div class="col-12 col-sm-6 col-md-3 cards">
            <img src="../assets/imgs/a-goop/gp2.png" class="img-fluid">
            <h2>OLHO NO OLHO</h2>
            <p>Não gostamos de cerimônia, e sim de gente. Nossa equipe é próxima entre si e com cada cliente. E isso não significa informalidade. Mas sim respeito e empatia para fazer a diferença entendendo o que cada um precisa.</p>
          </div>
          <div class="col-12 col-sm-6 col-md-3 cards">
            <img src="../assets/imgs/a-goop/gp3.png" class="img-fluid">
            <h2>PERFORMANCE</h2>
            <p>Queremos desempenho máximo para resultados concretos. E para chegar até lá respeitamos e investimos em processos e pessoas para garantir que todo mundo esteja na mesma página, sempre com profissionalismo.</p>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h1>Para quem</h1>
            <p>pensa grande e busca um parceiro de confiança para evoluir e sair na frente.</p>
          </div>
        </div>
      </div>
    </div>
    <div class="black">
      <div class="container">
        <p>Baseado em nosso conhecimento de mercado de autopeças e atentos as necessidades de distribuidores, lojistas e aplicadores – antes, tão distantes e pouco atendidos – pensamos: e se todos ficassem mais conectados para crescerem juntos!?</p>
        <br>
        <p>E, assim, surgiu a Goop, a solução completa para manutenção e reposição automotiva. Responsável por promover acesso às ferramentas certas para fazer a diferença: conhecimento, dados, serviços e as melhores peças Takao.</p>
      </div>
    </div>
    <div class="comp"></div>
    <div class="camisetas"></div>

    <div class="azul-claro">
      <div class="container">
        <div class="row">
          <div class="col-xs-12 col-md-12">
            <p>Aqui, literalmente, vestimos a camisa para resolver seus contratempos com total eficiência e qualidade, auxiliando uma comunidade potente que cresce cada vez mais, tornando-se um verdadeiro círculo veloz e virtuoso.</p>
            <br>
            <h3>Goop, turbinando seus negócios automotivos.</h3>
          </div>
        </div>
      </div>
    </div>
    <!-- FOOTER -->
    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Goop',
  components: {
    Navbar,
    Footer
  }
}
</script>